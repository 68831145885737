import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { shape } from 'prop-types';
import Layout from '../components/layout/layout';

import LoginComponent from '../components/login/index';
import {
  NavBar,
  Footer,
  Menu,
  Basket,
  pageWithNavigation,
} from '../components/common';
import { noAuthPages } from '../components/common/page/noAuthPages';

class LoginPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout
        title={data.wordings.fr.login.page_title}
        description={data.wordings.fr.login.description}
      >
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <LoginComponent wordings={data.wordings.fr} />
        <Footer />
      </Layout>
    );
  }
}

LoginPage.propTypes = {
  data: shape({}).isRequired,
};

export const query = graphql`
  query LoginPageQuery {
    wordings {
      fr {
        login {
          page_title
          description
          title
          title_signin
          email_placeholder
          pwd_placeholder
          reset_pwd
          log_button
          separator
          title_signup
          sub_description
          sub_button
          reset_pwd_button
        }
      }
    }
  }
`;

export default pageWithNavigation(noAuthPages(LoginPage));
