import React from 'react';
import PropTypes from 'prop-types';

const Field = ({
  name,
  inputType,
  label,
  wrapperClassName,
  value,
  onChange,
  errors,
}) => (
  <div className={`input-text-wrapper ${wrapperClassName}`}>
    <input
      value={value}
      name={name}
      className={`${value && value.length ? ' not-empty' : ''}${
        errors && errors.length ? ' error' : ''
      }`}
      type={inputType}
      onChange={e => {
        onChange(name, e.target.value);
      }}
    />
    <label htmlFor={name}>{label}</label>
  </div>
);

Field.defaultProps = {
  wrapperClassName: '',
  errors: [],
};

Field.propTypes = {
  name: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  inputType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Field;
