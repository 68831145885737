import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';

import { handleLogin, forgotPassword } from '../../actions/accountActions';

import Field from './forms/field';

import './styles.styl';
import './form.styl';
// import { timingSafeEqual } from 'crypto';

class LoginComponent extends Component {
  state = {
    email: '',
    password: '',
    resetPasswordEmail: '',
    forgottenPassword: false,
  };

  componentDidUpdate(prevProps) {
    const { access_token, cart } = this.props;
    if (prevProps.access_token !== access_token) {
      navigate(cart.nbItems > 0 ? 'checkoutShipping' : 'product');
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;

    this.props.handleLogin(email, password);
  };

  toggleForgotPassword = () => {
    this.setState(state => ({
      ...state,
      forgottenPassword: !state.forgottenPassword,
    }));
  };

  resetPassword = event => {
    event.preventDefault();
    const { forgotPassword } = this.props;
    const { resetPasswordEmail } = this.state;

    forgotPassword(resetPasswordEmail);
  };

  onInputChange = field => (name, value) => {
    this.setState({
      [field]: value,
    });
  };

  renderForgottenPassword() {
    const { resetPasswordEmail, forgottenPassword } = this.state;
    const {
      wordings: { login },
    } = this.props;

    return (
      <form onSubmit={this.resetPassword}>
        <div
          className={`forgottenPassword-wrapper${
            forgottenPassword ? ' active' : ''
          }`}
        >
          <p className="subtitle-text">{login.reset_pwd}</p>
          <Field
            label={'Email'}
            onChange={this.onInputChange('resetPasswordEmail')}
            name="resetPasswordEmail"
            inputType="text"
            value={resetPasswordEmail}
          />
          <div className="wrapper-button">
            <div />
            <button className="yellow-button" type="submit">
              {login.reset_pwd_button}
            </button>
          </div>
        </div>
      </form>
    );
  }

  renderForm() {
    const { email, password } = this.state;
    const {
      wordings: { login },
    } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        <p className="subtitle-text">{login.title_signin}</p>
        <div className="two-columns">
          <Field
            label={login.email_placeholder}
            onChange={this.onInputChange('email')}
            name="email"
            inputType="text"
            value={email}
          />
          <Field
            label={login.pwd_placeholder}
            onChange={this.onInputChange('password')}
            name="password"
            inputType="password"
            value={password}
          />
        </div>
        <div className="wrapper-button">
          <div onClick={this.toggleForgotPassword}>
            <span className="reset-password">{login.reset_pwd}</span>
          </div>
          <button className="yellow-button" type="submit">
            {login.log_button}
          </button>
        </div>
      </form>
    );
  }

  renderSeparationOr() {
    const {
      wordings: { login },
    } = this.props;

    return (
      <div className="separation-wrapper">
        <div className="line-separator" />
        <div className="text-wrapper">{login.separator}</div>
      </div>
    );
  }

  renderNewCustomer() {
    const {
      wordings: { login },
    } = this.props;
    return (
      <Fragment>
        <p className="subtitle-text">{login.title_signup}</p>
        <p className="new-customer-description">{login.sub_description}</p>
        <button
          className="yellow-button"
          onClick={() => {
            navigate('register');
          }}
        >
          {login.sub_button}
        </button>
      </Fragment>
    );
  }

  render() {
    const {
      wordings: { login },
    } = this.props;

    return (
      <section className="login-wrapper">
        <div className="title-wrapper">
          <h1>{login.title}</h1>
        </div>
        <div className="form-wrapper need-check">
          {this.renderForm()}
          {this.renderForgottenPassword()}
          {this.renderSeparationOr()}
          {this.renderNewCustomer()}
        </div>
      </section>
    );
  }
}

LoginComponent.propTypes = {
  access_token: PropTypes.string.isRequired,
  cart: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({ account: { access_token }, shop: { cart } }) => ({
  access_token,
  cart,
});

export default connect(
  mapStateToProps,
  { handleLogin, forgotPassword }
)(LoginComponent);
